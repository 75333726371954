/*=============== TESTIMONIAL ===============*/
.testimonial__card{
    background-color: var(--container-color);
    padding: 1.25rem 1.5rem;
    border-radius: 1.5rem;
    margin-bottom: 3rem;
  }

.testimonial__img{
    width: 60px;
    height: 60px;
    border-radius: 3rem;
    margin-bottom: 1rem;
  }

.testimonial__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: .25rem;
  }

.testimonial__description{
    font-size: var(--small-font-size);
  }

  /* Swiper class */
.swiper-pagination-bullet{
    background-color: var(--text-color-light);
  }

.swiper-pagination-bullet-active{
    background-color: var(--first-color);
  }

/*==========
    Color changes in some parts of the website, in light theme
==========*/
.light-theme .testimonial__card{
    box-shadow: 0 2px 8px hsl(var(--second-hue), 48%, 8%, .1);
  }

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {

}
/* For medium devices */
@media screen and (min-width: 576px) {

}
@media screen and (min-width: 767px) {

}
/* For large devices */
@media screen and (min-width: 992px) {
    .testimonial__container{
        width: 750px;
      }
      .testimonial__card{
        padding: 1.5rem 2rem;
      }
}
