/*=============== SKILLS ===============*/
.skills__container{
    row-gap: 2rem;
    padding-top: 1rem;
  }
  
.skills__content{
    background-color: var(--container-color);
    padding: 1.5rem;
    border-radius: 1.25rem;
  }
  
.skills__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    color: var(--first-color);
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
.skills__box{
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
  }
  
.skills__group{
    display: grid;
    align-content: flex-start;
    row-gap: 1rem;
  }
  
.skills__data{
    display: flex;
    column-gap: .5rem;
  }
  
.skills .bx-badge-check{
    font-size: 1rem;
    color: var(--first-color);
  }
  
.skills__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
  }
  
.skills__level{
    font-size: var(--tiny-font-size);
  }

/*========== 
    Color changes in some parts of the website, in light theme
==========*/
.light-theme .skills__content{
    box-shadow: 0 2px 8px hsl(var(--second-hue), 48%, 8%, .1);
  }

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
    .skills__box{
        column-gap: 1rem;
      }
      .skills__name{
        font-size: var(--small-font-size);
      }
}
/* For medium devices */
@media screen and (min-width: 576px) {
    .skills__container{
        justify-content: center;
      }
      .skills__content{
        padding: 2rem 4rem;
      }
}
@media screen and (min-width: 767px) {
  
}
/* For large devices */
@media screen and (min-width: 992px) {
    .skills__container{
        grid-template-columns: repeat(2, 350px);
        column-gap: 3rem;
      }
}