/*===============HEADER===============*/
.header{  
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    z-index: var(--z-fixed);
    /* transition: .4s; */
     /* for light mode animation */
  }
/*========== 
    Color changes in some parts of the website, in light theme
==========*/
.light-theme .scroll-header{
    box-shadow: 0 2px 4px hsl(0, 0%, 1%, .1);
  }
  
/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
    
}
/* For medium devices */
@media screen and (min-width: 576px) {
   
}
@media screen and (min-width: 767px) {
  
}
/* For large devices */
@media screen and (min-width: 992px) {
    
}