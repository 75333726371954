/*=============== SERVICES ===============*/
.services__container{
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding-top: 1rem;
  }
  
.services__card{
    background-color: var(--container-color);
    padding: 3rem 1.5rem 1.5rem;
    border-radius: 1rem;
  }
  
.services__title{
    font-size: var(--h3-font-size);
    text-align: left;
    margin-bottom: 2.5rem;
  }
  
.services__button{
    color: var(--first-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: .25rem;
    cursor: pointer;
  }
  
.services__button:hover .services__icon{
    transform: translateX(.25rem);
  }
  
.services__icon{
    font-size: 1rem;
    transition: .4s;
  }
  
/* Services modal */
.services__modal{
    position: fixed;
    inset: 0;
    background-color: hsl(var(--second-hue), 28%, 16%, .7);
    padding: 2rem 1rem;
    display: grid;
    place-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .4s;
    z-index: var(--z-modal);
  }
  
.services__modal-content{
    position: relative;
    background-color: var(--body-color);
    padding: 4.5rem 1.5rem 2.5rem;
    border-radius: 1.5rem;
  }
  
.services__modal-title,
.services__modal-description{
    text-align: center;
  }
  
.services__modal-title{
    font-size: var(--h3-font-size);
    color: var(--first-color);
    margin-bottom: 1rem;
  }
  
.services__modal-description{
    font-size: var(--small-font-size);
    margin-bottom: 2rem;
  }
  
.services__modal-list{
    display: grid;
    row-gap: .75rem;
  }
  
.services__modal-item{
    display: flex;
    align-items: flex-start;
    column-gap: .5rem;
  }
  
.services__modal-icon{
    font-size: 1.5rem;
    color: var(--first-color);
  }
  
.services__modal-info{
    font-size: var(--small-font-size);
  }
  
.services__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--first-color);
    cursor: pointer;
  }
  
/*Active modal*/
.active-modal{
    opacity: 1;
    visibility: visible;
  }

/*========== 
    Color changes in some parts of the website, in light theme
==========*/
.light-theme .services__card{
  box-shadow: 0 2px 8px hsl(var(--second-hue), 48%, 8%, .1);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
    .services__container{
        grid-template-columns: 145px;
        justify-content: center;
      }
}
/* For medium devices */
@media screen and (min-width: 576px) {
    .services__container{
        grid-template-columns: repeat(2, 160px);
        justify-content: center;
      }
      .services__modal-content{
        width: 500px;
        padding: 4.5rem 2.5rem 2.5rem;
      }
      .services__modal-description{
        padding: 0 3.5rem;
      }
}
@media screen and (min-width: 767px) {
  
}
/* For large devices */
@media screen and (min-width: 992px) {
    .services__container{
        grid-template-columns: repeat(3, 192px);
        column-gap: 3rem;
      }
      .services__card{
        padding: 5rem 2rem 1.5rem;
      }
}