/*=============== WORK ===============*/
.work__container{
    padding-top: 1rem;
  }
  
  .work__filters{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .75rem;
    margin-bottom: 2rem;
  }
  
  .work__item{
    cursor: pointer;
    color: var(--title-color);
    padding: .25rem;
    font-weight: var(--font-medium);
    border-radius: .5rem;
  }
  
  .work__card{
    background-color: var(--container-color);
    padding: 1rem;
    border-radius: 1rem;
  }
  
  .work__img{
    border-radius: 1rem;
    margin-bottom: .75rem;
  }
  
  .work__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: .25rem;
  }
  
  .work__button{
    width: max-content;
    color: var(--first-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: .25rem;
  }
  
  .work__button:hover .work__icon{
    transform: translateX(.25rem);
  }
  
  .work__icon{
    font-size: 1rem;
    transition: .4s;
  }
  
  /* Active item work*/
  .active-work{
    background-color: var(--first-color);
    color: var(--body-color);
  }
  
/* Works modal */
.work__modal{
  position: fixed;
  inset: 0;
  background-color: hsl(var(--second-hue), 28%, 16%, .7);
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  visibility: hidden;
  opacity: 0;
  transition: .4s;
  z-index: var(--z-modal);
}

.work__modal-content{
  position: relative;
  background-color: var(--body-color);
  padding: 4.5rem 1.5rem 2.5rem;
  border-radius: 1.5rem;
}

.work__modal-title,
.work__modal-description{
  text-align: center;
}

.work__modal-title{
  font-size: var(--h3-font-size);
  color: var(--first-color);
  margin-bottom: 1rem;
}

.work__modal-description{
  font-size: var(--small-font-size);
  margin-bottom: 2rem;
}

.work__modal-list{
  display: grid;
  row-gap: .75rem;
}

.work__modal-item{
  display: flex;
  align-items: flex-start;
  column-gap: .5rem;
}

.work__modal-icon{
  font-size: 1.5rem;
  color: var(--first-color);
}

.work__modal-info{
  font-size: var(--small-font-size);
}

.work__modal-close{
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

/*Active modal*/
.active-modal{
  opacity: 1;
  visibility: visible;
}

/*========== 
    Color changes in some parts of the website, in light theme
==========*/
.light-theme .active-work{
    color: var(--title-color);
  }
.light-theme .work__card{
    box-shadow: 0 2px 8px hsl(var(--second-hue), 48%, 8%, .1);
  }

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
    .work__item{
        font-size: var(--small-font-size);
      }
      .work__filters{
        column-gap: .25rem;
      }
}
/* For medium devices */
@media screen and (min-width: 576px) {
    .work__container{
        justify-content: center;
      }
      .work__img{
        width: 295px;
      }
      .work__modal-content{
        width: 500px;
        padding: 4.5rem 2.5rem 2.5rem;
      }
      .work__modal-description{
        padding: 0 3.5rem;
      }
}
@media screen and (min-width: 767px) {
    .work__container{
        grid-template-columns: repeat(2, max-content);
      }
}
/* For large devices */
@media screen and (min-width: 992px) {
    .work__container{
        gap: 3rem;
      }
      .work__card{
        padding: 1.25rem;
      }
      .work__img{
        margin-bottom: 1rem;
      }
      .work__title{
        margin-bottom: .5rem;
      }
}